"use client";

import { GetCardRatingResponse } from "@/lib/ontrade";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { Info, Share, X } from "lucide-react";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { Textfit } from "react-textfit";
import { toast } from "sonner";
import { Button, buttonVariants } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { useAuth } from "@clerk/nextjs";
export const CardHeader = ({
  title,
  hideShareButton = false,
  HeaderInnerComponent,
  rating,
  externalLink,
  isShowingRating = false,
  setIsShowingRating,
  showRatings = true
}: {
  title: string;
  hideShareButton?: boolean;
  HeaderInnerComponent?: React.ReactNode;
  rating?: number;
  externalLink?: string;
  isShowingRating?: boolean;
  setIsShowingRating?: (isShowingRating: boolean) => void;
  showRatings?: boolean;
}) => {
  return <div className="w-full flex justify-between items-center gap-2 px-4 py-2 rounded-t-lg h-14" data-sentry-component="CardHeader" data-sentry-source-file="CardComponents.tsx">
      <Textfit mode="single" max={22} min={10} className={cn("font-light tracking-tighter text-[#00000059] w-full")} data-sentry-element="Textfit" data-sentry-source-file="CardComponents.tsx">
        {title}
      </Textfit>
      <div className={"flex flex-row gap-1"}>
        {HeaderInnerComponent}
        {!isShowingRating && showRatings && <RatingsButton rating={rating} isShowingRating={isShowingRating} setIsShowingRating={setIsShowingRating} />}
        {!hideShareButton && <ShareButton cardTitle={title} externalLink={externalLink} />}
        {isShowingRating && <Button variant="ghost" size="icon" onClick={() => setIsShowingRating?.(false)} className="cursor-pointer">
            <X className="size-5" />
          </Button>}
      </div>
    </div>;
};
export const LoadingCard = () => {
  return <div data-sentry-component="LoadingCard" data-sentry-source-file="CardComponents.tsx">Loading...</div>;
};
export const ErrorCard = ({
  title,
  errorMessage,
  className,
  ticker,
  refetch
}: {
  title: string;
  errorMessage: string;
  className?: string;
  ticker?: string;
  refetch?: () => void;
}) => {
  return <div className={cn("w-[82.5vw] md:w-80 border border-border rounded-lg shadow-md mb-2 flex flex-col", className)} data-sentry-component="ErrorCard" data-sentry-source-file="CardComponents.tsx">
      <CardHeader title={title} hideShareButton data-sentry-element="CardHeader" data-sentry-source-file="CardComponents.tsx" />
      <div className="grow p-4 text-center flex flex-col gap-4">
        <div className="flex flex-col grow">
          <p className="text-muted-foreground font-semibold text-lg">
            Something went wrong
          </p>
          <p className="text-muted-foreground text-xs">{errorMessage}</p>
        </div>
        {refetch && <Button className="flex" variant={"outline"} onClick={e => {
        e.preventDefault();
        refetch();
      }}>
            Try again
          </Button>}
      </div>
      <div className="border-t border-border pt-2">
        <CardFooter title={title} isFlipped={false} setIsFlipped={undefined} ticker={ticker} data-sentry-element="CardFooter" data-sentry-source-file="CardComponents.tsx" />
      </div>
    </div>;
};
export const FlipButton = ({
  cardTitle,
  isFlipped,
  setIsFlipped,
  ticker
}: {
  cardTitle: string;
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
  ticker?: string;
}) => {
  const posthog = usePostHog();
  return <button className="px-0 text-primary flex flex-row items-center" onClick={() => {
    setIsFlipped(!isFlipped);
    posthog.capture("card_flipped", {
      card: cardTitle.toLowerCase().replace(/ /g, "_"),
      ticker: ticker?.toUpperCase()
    });
  }} data-sentry-component="FlipButton" data-sentry-source-file="CardComponents.tsx">
      {isFlipped ? <X className="size-4" /> : <Info className="size-4" />}
      <span className="ml-1 text-xs font-medium">Insights details</span>
    </button>;
};
export const ShareButton = ({
  cardTitle,
  externalLink
}: {
  cardTitle: string;
  externalLink?: string;
}) => {
  const posthog = usePostHog();
  if (externalLink) {
    return <Button variant="ghost" size="icon" className="p-0 w-fit cursor-pointer" onClick={e => {
      e.preventDefault();
      // copy to clipboard
      navigator.clipboard.writeText(externalLink);
      toast.success("Copied to clipboard", {
        description: externalLink
      });
      posthog.capture("card_shared", {
        card: cardTitle.toLowerCase().replace(/ /g, "_")
      });
    }}>
        <Share className="size-5" />
      </Button>;
  }
  return <Link href="/invites" className={cn("p-0 w-fit", buttonVariants({
    variant: "ghost",
    size: "icon"
  }))} onClick={() => {
    posthog.capture("card_shared", {
      card: cardTitle.toLowerCase().replace(/ /g, "_")
    });
  }} data-sentry-element="Link" data-sentry-component="ShareButton" data-sentry-source-file="CardComponents.tsx">
      <Share className="size-5" data-sentry-element="Share" data-sentry-source-file="CardComponents.tsx" />
    </Link>;
};
export function BackSideOfACard({
  info,
  ticker,
  title,
  isFlipped,
  setIsFlipped
}: {
  info: InfoPoint[];
  ticker?: string;
  title: string;
  isFlipped: boolean;
  setIsFlipped?: (isFlipped: boolean) => void;
}) {
  return <div className="h-[calc(100%-56px)] w-full rounded-b-md flex flex-col justify-between" data-sentry-component="BackSideOfACard" data-sentry-source-file="CardComponents.tsx">
      <div className="p-4 overflow-y-auto grow">
        <ul className="list-disc list-outside px-4 flex flex-col gap-4">
          {info.map(item => <li key={item.title}>
              <p className="font-bold">{item.title}</p>
              {item.description && <p>{item.description}</p>}
              {item.steps && <ul className="list-disc list-outside px-4 flex flex-col gap-2">
                  {item.steps.map(step => <li key={step.title}>
                      <p className="font-bold">{step.title}</p>
                      {step.description && <p>{step.description}</p>}
                    </li>)}
                </ul>}
            </li>)}
        </ul>
      </div>
      <div className="border-t border-border">
        <CardFooter title={title} ticker={ticker} isFlipped={isFlipped} setIsFlipped={setIsFlipped} data-sentry-element="CardFooter" data-sentry-source-file="CardComponents.tsx" />
      </div>
    </div>;
}
export type InfoPoint = {
  title: string;
  description?: string;
  steps?: InfoPoint[];
};
export const CardValue = ({
  value,
  title
}: {
  value: string;
  title: string;
}) => {
  return <div className="px-4 py-2 flex flex-row items-center justify-between border-t border-border" data-sentry-component="CardValue" data-sentry-source-file="CardComponents.tsx">
      <p className="text-green-600 text-sm font-semibold">{title}</p>
      <p className="text-green-600 text-sm font-semibold text-right">{value}</p>
    </div>;
};
export const CardFooter = ({
  ticker,
  title,
  isFlipped,
  setIsFlipped
}: {
  ticker?: string;
  title: string;
  isFlipped: boolean;
  setIsFlipped?: (isFlipped: boolean) => void;
}) => {
  return <div className={cn("flex flex-row justify-between items-center px-4 min-h-8", !setIsFlipped && "justify-center")} data-sentry-component="CardFooter" data-sentry-source-file="CardComponents.tsx">
      <p className="text-xs text-center text-[#999] font-medium">
        ontrade.ai{ticker && "/" + ticker.toLowerCase()}
      </p>
      {setIsFlipped && <FlipButton cardTitle={title} isFlipped={isFlipped} setIsFlipped={setIsFlipped} />}
    </div>;
};
export const CardContainer = ({
  isFlipped = false,
  info = [],
  ticker,
  children,
  className,
  headline,
  cardValue,
  showError,
  errorMessage,
  refetch,
  headerTitle,
  setIsFlipped,
  HeaderInnerComponent,
  externalLink,
  showRatings = true
}: {
  isFlipped?: boolean;
  setIsFlipped?: (isFlipped: boolean) => void;
  info?: InfoPoint[];
  HeaderInnerComponent?: React.ReactNode;
  ticker: string;
  children: React.ReactNode;
  className?: string;
  headline?: string;
  cardValue?: {
    text: string;
  };
  showError?: boolean;
  errorMessage?: string;
  refetch?: () => void;
  headerTitle: string;
  externalLink?: string;
  showRatings?: boolean;
}) => {
  const cardName = headerTitle.toLowerCase().replace(/ /g, "_");
  const {
    data: ratingData,
    refetch: refetchRatingData
  } = trpc.ratings.getCardRating.useQuery({
    ticker: ticker || "",
    cardName: cardName
  }, {
    enabled: showRatings
  });
  const [isShowingRating, setIsShowingRating] = useState(false);
  if (showError) return <ErrorCard title={headerTitle} errorMessage={errorMessage || "Something went wrong"} refetch={refetch} ticker={ticker} className={className} />;
  return <div className={cn("w-[82.5vw] md:w-80 rounded-lg shadow-md mb-2 min-h-[400px] bg-white flex flex-col", className)} data-sentry-component="CardContainer" data-sentry-source-file="CardComponents.tsx">
      {isShowingRating ? <>
          <CardHeader title={headerTitle} rating={ratingData?.rating?.rating ? ratingData?.card?.average_rating : undefined} isShowingRating={isShowingRating} hideShareButton setIsShowingRating={setIsShowingRating} />
          <RatingsCard ratingData={ratingData} setIsShowingRating={setIsShowingRating} ticker={ticker || ""} cardName={cardName} refetch={refetchRatingData} />
        </> : isFlipped && info.length > 0 ? <>
          <CardHeader title={headerTitle} HeaderInnerComponent={HeaderInnerComponent} externalLink={externalLink} showRatings={showRatings} rating={ratingData?.rating?.rating ? ratingData.card.average_rating : undefined} isShowingRating={isShowingRating} setIsShowingRating={setIsShowingRating} />
          <BackSideOfACard info={info} ticker={ticker} title={headerTitle} isFlipped={isFlipped} setIsFlipped={setIsFlipped} />
        </> : <>
          <div className="grow flex flex-col flex-1">
            <CardHeader title={headerTitle} HeaderInnerComponent={HeaderInnerComponent} externalLink={externalLink} isShowingRating={isShowingRating} setIsShowingRating={setIsShowingRating} showRatings={showRatings} rating={ratingData?.rating?.rating ? ratingData.card.average_rating : undefined} />
            {headline && <p className={cn("font-bold pt-2 pb-2 px-4", cardValue && "pb-0")}>
                {headline}
              </p>}
            {cardValue && <p className="text-green-600 font-semibold text-xs px-4 pb-2">
                {cardValue.text}
              </p>}
            {children}
          </div>
          <CardFooter title={headerTitle} isFlipped={isFlipped} setIsFlipped={setIsFlipped} ticker={ticker} />
        </>}
    </div>;
};
export const RatingsButton = ({
  rating,
  isShowingRating,
  setIsShowingRating
}: {
  rating?: number;
  isShowingRating: boolean;
  setIsShowingRating?: (isShowingRating: boolean) => void;
}) => {
  const session = useAuth();
  const percentage = rating ? Math.round(rating / 5 * 100) : 0;
  let ratingText = rating?.toFixed(1);
  if (ratingText?.charAt(ratingText.length - 1) === "0") {
    ratingText = ratingText?.slice(0, -2);
  }
  return <button className="flex flex-row items-center gap-0 cursor-pointer" onClick={() => {
    if (!session.isSignedIn) {
      toast.error("Please sign in to rate this insight");
      return;
    }
    setIsShowingRating?.(!isShowingRating);
  }} data-sentry-component="RatingsButton" data-sentry-source-file="CardComponents.tsx">
      <div className="flex flex-col items-center">
        <div className="relative">
          <StarFilledIcon className="size-6 text-muted-foreground/20" data-sentry-element="StarFilledIcon" data-sentry-source-file="CardComponents.tsx" />
          <div className="absolute inset-0 overflow-hidden" style={{
          width: `${percentage}%`
        }}>
            <StarFilledIcon className="size-6 text-primary" data-sentry-element="StarFilledIcon" data-sentry-source-file="CardComponents.tsx" />
          </div>
        </div>
        {!rating && <p className="text-muted-foreground text-xs w-10">Rate it</p>}
      </div>
      <p className={cn("tracking-tighter font-light")}>{ratingText}</p>
    </button>;
};
export const RatingsCard = ({
  ratingData,
  setIsShowingRating,
  ticker,
  cardName,
  refetch
}: {
  ratingData: GetCardRatingResponse | null | undefined;
  setIsShowingRating: (isShowingRating: boolean) => void;
  ticker: string;
  cardName: string;
  refetch: () => void;
}) => {
  const [comment, setComment] = useState(ratingData?.rating?.comment || "");
  const [userRating, setUserRating] = useState(ratingData?.rating?.rating || 0);
  let averageRating = ratingData?.card.average_rating || 0;
  let formattedAverageRating = averageRating.toFixed(1);
  if (formattedAverageRating.charAt(formattedAverageRating.length - 1) === "0") {
    formattedAverageRating = formattedAverageRating.slice(0, -2);
  }
  const {
    mutateAsync: rateCard,
    isPending
  } = trpc.ratings.rateCard.useMutation();
  const setRating = (rating: number) => {
    setUserRating(rating);
  };
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await rateCard({
      ticker: ticker,
      cardName: cardName,
      rating: userRating,
      comment: comment
    });
    await refetch();
    toast.success("Rating submitted");
    setIsShowingRating(false);
  };
  return <div className="flex flex-col gap-4 p-4 items-center" data-sentry-component="RatingsCard" data-sentry-source-file="CardComponents.tsx">
      <div>
        <h4 className="font-semibold">Rate this insight</h4>
        <p>
          Is this insight clear and useful? Rate it to improve what you see on
          OnTrade.
        </p>
      </div>
      <div className="flex flex-row items-center gap-2">
        {[1, 2, 3, 4, 5].map(rating => <button key={rating} onClick={() => setRating(rating)} className="p-0 w-fit">
            <StarFilledIcon className={`size-10 ${userRating && rating <= userRating ? "text-primary" : "text-muted-foreground/20"}`} />
          </button>)}
      </div>
      {ratingData?.card?.total_ratings && ratingData?.card.total_ratings > 1 && <p className="font-semibold text-xs text-center">
          {`${formattedAverageRating} average from ${ratingData?.card.total_ratings} ratings`}
        </p>}
      <Textarea placeholder="Optional: Share your thoughts on this feature with OnTrade and other users." value={comment} className="min-h-[100px] resize-none" onChange={e => setComment(e.target.value)} data-sentry-element="Textarea" data-sentry-source-file="CardComponents.tsx" />
      <Button className="w-full" onClick={handleSubmit} disabled={isPending || !userRating} data-sentry-element="Button" data-sentry-source-file="CardComponents.tsx">
        Submit
      </Button>
    </div>;
};